
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<SalesOffer[]>([]);
    const productList = ref<SalesOffer[]>([]);
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_data = ref<Array<Supplier>>([]);

    const warehouse_branch_data = ref([]);
    const branch_data = ref([]);
    const type_data = ref([]);
    const incoterms_data = ref([]);
    const expeceted_delivery_data = ref([]);
    const tableData = ref<Array<SalesOffer>>([]);
    const dialogSelectProductTableVisible = ref(false);
    const uom_data = ref<Array<UOM>>([]);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);
    const po_data = ref([]);
    const is_consignee_data = ref([
      { id: 1, name: "Yes" },
      { id: 2, name: "No" },
    ]);

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    interface UOM {
      uom_id: number;
      uom_display_name: string;
    }
    getUOM();

    interface SalesOffer {
      stock_purchase_order_details_id: string;
      stock_purchase_order_no: string;
      product_remark: string;
      product_name: string;
      product_qunatity: string;
      uom: string;
      product_rate: string;
      product_total: string;
      dispatch_qty: string;
    }

    // const tableData: Product[] = [
    //   {
    //     prno: "PR_1",
    //     product: "prod1",
    //     quantity: "39",
    //     uom: "kg",
    //   },
    //   {
    //     prno: "PR_2",
    //     product: "prod2",
    //     quantity: "9",
    //     uom: "kg",
    //   },
    //   {
    //     prno: "PR_3",
    //     product: "prod3",
    //     quantity: "3",
    //     uom: "kg",
    //   },
    // ];

    getAddData();
    getCompanyList();
    getIncoterms();
    getDeliveryTerms();
    getWarehouseList("warehouse");
    getPurchaseRequitionType();
    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      supplier_select: "",
      pono: [],
      supplier_branch_select: "",
      warehouse_select: "",
    });

    // const myData = ref([
    //   {
    //     prno: "PR-1",
    //     product: "prod1",
    //     quantity: "39",
    //     uom: "kg",
    //   },
    // ]);

    const myData = ref<Array<SalesOffer>>([]);
    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Customer is required",
          trigger: "change",
        },
      ],
      pono: [
        {
          required: true,
          message: "PO No. is required",
          trigger: "change",
        },
      ],
      supplier_branch_select: [
        {
          required: true,
          message: "Supplier Branch is required",
          trigger: "change",
        },
      ],
      warehouse_select: [
        {
          required: true,
          message: "Warehouse is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = multipleSelection.value.map((item) => {
        return {
          stock_purchase_order_details_id: item.stock_purchase_order_details_id,
          dispatch_quantity: item.dispatch_qty,
        };
      });
      let validateProductList = multipleSelection.value.filter((item) => {
        return item.dispatch_qty != "";
      });
      if (
        validateProductList === [] ||
        validateProductList.length != multipleSelection.value.length
      ) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Fill Dispatch Quantity in Selected Products!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One PO.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        supplier_company_id: data.supplier_select,
        supplier_company_branch_id: data.supplier_branch_select,
        delivery_warehouse_id: data.warehouse_select,
        product_details: product_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/mm_purchase_dispatch_order/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPlanningIndentRef.value);
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    // const setCompanyData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());

    //   var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
    //   var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
    //   var yy = data.incorporationateDate.getFullYear();
    //   var dtt = yy + "-" + mo + "-" + dt;
    //   var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

    //   const db_data = {
    //     company_name: data.name,
    //     company_name_alternative: data.alternativename,
    //     company_nob: data.nob_select,
    //     company_group_type: data.group_type_select,
    //     company_industry_type: data.industry_type_select,
    //     company_business_type: data.business_type_select,
    //     company_roc: data.roc_select === "" ? 0 : data.roc_select,
    //     company_doi: dtt,
    //     company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
    //     company_registration_number:
    //       data.registrationNo === "" ? 0 : data.registrationNo,
    //     company_pan: data.pan,
    //     company_website: data.website,
    //     company_verified_yes_no: false,
    //     active: true,
    //     created_user_id: user.user_id,
    //   };

    //   console.log(db_data);

    //   await store
    //     .dispatch(Actions.CUST_ADD_COMPANY, db_data)
    //     .then(({ data }) => {
    //       console.log(data);

    //       if (data.company_id) {
    //         setTimeout(() => {
    //           loading.value = false;
    //           //
    //           Swal.fire({
    //             text: "Company has been successfully created.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             hideModal(addCompanyModalRef.value);
    //             router.go(0);
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getWarehouseList(callFrom) {
      let values = {};
      if (callFrom === "supplier") {
        values = {
          company_id: formData.value["supplier_select"],
        };
      } else {
        values = {
          company_id: 1,
        };
      }
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          if (callFrom === "supplier") {
            branch_data.value = data;
          } else {
            warehouse_branch_data.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      // myData.value.push({
      //   prno: "PR-1",
      //   product: "prod1",
      //   quantity: "39",
      //   uom: "kg",
      // });
      //   console.log("table data.." + tableData.value);
    }
    function deleteRow(row) {
      myData.value.splice(row, 1);
    }
    async function getPurchaseRequitionType() {
      await store
        .dispatch(ActionsFi.CUST_GET_REQUISITION_TYPES)
        .then(({ data }) => {
          debugger;
          type_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList() {
      //trade type 1 for Supplier
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          supplier_data.value = data;
          consignee_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getIncoterms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPOData() {
      let values = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_branch_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_PURCHASE_ORDERS_NO_LIST, values)
        .then(({ data }) => {
          debugger;
          po_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: SalesOffer[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectProductTableVisible.value = false;
    };

    async function getTableData() {
      debugger;
      let params = {
        stock_purchase_order_ids: formData.value["pono"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_PURCHASE_ORDER_BY_ID_LIST, params)
        .then(({ data }) => {
          debugger;
          data = data.map((po) => {
            let value = {
              stock_purchase_order_details_id:
                po.stock_purchase_order_details_id,
              stock_purchase_order_no: po.stock_purchase_order_no,
              product_remark: po.product_remark,
              product_name: po.product_name,
              product_qunatity: po.product_qunatity + " " + po.uom,
              uom: po.uom,
              product_rate: po.product_rate,
              product_total: po.product_total,
              dispatch_qty: po.product_qunatity,
            };

            return value;
          });
          tableData.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      if (formData.value["type_select"] === "") {
        Swal.fire({
          text: "Please select purchase requisition type first!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        dialogSelectProductTableVisible.value = true;
      }
    };

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one user",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        productList.value = multipleSelection.value;
        myData.value = multipleSelection.value;
        dialogSelectProductTableVisible.value = false;
      }
    };
    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      addPlanningIndentRef,
      roc_list_data,
      formData,
      nob_data,
      type_data,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
      addProduct,
      myData,
      deleteRow,
      getPurchaseRequitionType,
      dialogSelectProductTableVisible,
      tableData,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      onContinue,
      onCancle,
      getTableData,
      openDilogue,
      supplier_data,
      consignee_data,
      incoterms_data,
      is_consignee_data,
      expeceted_delivery_data,
      getIncoterms,
      po_data,
      branch_data,
      warehouse_branch_data,
      getWarehouseList,
      getDeliveryTerms,
      getPOData,
    };
  },
});
